import { render, staticRenderFns } from "./registerEntry_tl3.vue?vue&type=template&id=3d9138f0&scoped=true"
import script from "./registerEntry_tl3.vue?vue&type=script&lang=js"
export * from "./registerEntry_tl3.vue?vue&type=script&lang=js"
import style0 from "./registerEntry_tl3.vue?vue&type=style&index=0&id=3d9138f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d9138f0",
  null
  
)

export default component.exports